<template>
  <v-container class="white py-0 px-0" fluid>
    <v-card flat>
      <v-toolbar flat dense class="grey lighten-5">
        <v-toolbar-title class="py-3 px-0">{{
          $t("route.aor")
        }}</v-toolbar-title>
        <v-spacer />
        <v-divider vertical inset class="d-none d-md-flex mx-2" />
        <v-text-field
          class="d-none d-md-flex"
          v-model="search"
          append-icon="mdi-magnify"
          :label="$t('general.search')"
          single-line
          hide-details
          dense
          style="width:0"
        ></v-text-field>
        <v-divider vertical inset class="d-none d-md-flex mx-2" />
        <v-btn
          text
          x-small
          color="primary"
          :disabled="!selected"
          @click="updateHubsAoR()"
        >
          <v-icon>mdi-pencil-box</v-icon>
          <span>{{ $t("aor.updateAoR") }}</span>
        </v-btn>
      </v-toolbar>

      <v-card-text class="overflow-y-auto px-0 py-0" :style="viewHeight()">
        <v-data-table
          dense
          :headers="headers"
          :items="getAor"
          item-key="id"
          :search="search"
          show-select
          single-select
          @item-selected="selectionChanged"
          :value="selection"
          :loading="loading"
          :loading-text="$t('messages.loading')"
        >
          <template #item.data-table-select="{ item, isSelected, select }">
            <v-simple-checkbox
              on-icon="mdi-checkbox-marked-circle"
              off-icon="mdi-power-off"
              :value="isSelected"
              color="blue"
              light
              @input="select($event)"
              :ripple="false"
            ></v-simple-checkbox>
          </template>

          <template #item.aor="{ item }">
            <v-tooltip bottom v-for="a in item.aor" :key="a.id">
              <template v-slot:activator="{ on, attrs }">
                <v-chip small class="pa-1 ma-1" outlined label v-bind="attrs" v-on="on">
                  {{ getHub(a.hub_type_id).name }}
                  <v-icon color="orange" small v-if="a.partially"
                    >mdi-square-opacity</v-icon
                  >
                  <v-icon color="blue" small v-if="!a.partially"
                    >mdi-square</v-icon
                  >
                </v-chip>
              </template>
              <span
                >{{ getHub(a.hub_type_id).name }}
                <span v-if="a.partially">({{ $t("aor.partially") }})</span>
                <span v-if="!a.partially">({{ $t("aor.fully") }})</span>
              </span>
            </v-tooltip>
            <!-- <v-icon
              class="mr-4"
              :color="!!item['Incident Resolved'] ? 'green' : 'orange'"
              >{{
                !!item["Incident Resolved"]
                  ? "mdi-check"
                  : "mdi-checkbox-blank-outline"
              }}</v-icon
            > -->
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import AorDialog from "@/views/ac/AorDialog";

export default {
  name: "Aor",
  // eslint-disable-next-line vue/no-unused-components
  components: { AorDialog },
  data() {
    return {
      search: "",
      loading: false,
      deleteDialog: false,
      hubs: [],
      headers: [
        // { text: this.$t("general.id"), align: "start", value: "id", width: 50 },
        {
          text: this.$t("general.governorate"),
          value: "governorate",
          width: 200,
        },
        { text: this.$t("general.district"), value: "district" },
        { text: this.$t("general.hubs"), value: "aor" },
      ],
    };
  },
  mounted() {
    this.loading = true;
    this.loadAor()
      .then(() => {
        this.loading = false;
      })
      .catch(() => (this.loading = false));
    this.loadTypes().then(() => (this.hubs = this.getTypesByGroup("HUB_TYPE")));
  },
  computed: {
    ...mapGetters("types", ["getTypesByGroup"]),
    ...mapGetters("aor", ["getAor", "getSelectedAor"]),
    ...mapState({
      selected() {
        return this.getSelectedAor;
      },
      selection() {
        const s = this.selected;
        return s ? [s] : [];
      },
    }),
  },
  methods: {
    ...mapActions("aor", [
      "loadAor",
      "deleteAor",
      "saveAor",
      "setSelectedAor",
      "clearSelectedAor",
    ]),
    ...mapActions("types", ["loadTypes"]),
    ...mapActions("ui", ["showDialog", "closeDialog"]),

    getHub(id) {
      const empty = { name: null, partially: null };
      const hub = this.hubs.find((f) => f.id === id);
      return hub ? hub : empty;
    },
    selectionChanged(event) {
      if (event.value) {
        this.setSelectedAor(event.item);
      } else {
        this.clearSelectedAor();
      }
    },
    updateHubsAoR() {
      console.log(
        this.hubs.filter((f) =>
          this.selected.aor.map((m) => m.hub_type_id).includes(f.id)
        )
      );
      if (this.selected) {
        const dialog = {
          title: this.$t("aor.updateAoR"),
          component: AorDialog,
          params: {
            body: "Please assign hubs to corresponding area of responsibiity.",
            hubs: this.hubs,
            fully: this.hubs
              .filter((f) =>
                this.selected.aor
                  .filter((f) => !f.partially)
                  .map((m) => m.hub_type_id)
                  .includes(f.id)
              )
              .map((f) => f.id),
            partially: this.hubs
              .filter((f) =>
                this.selected.aor
                  .filter((f) => f.partially)
                  .map((m) => m.hub_type_id)
                  .includes(f.id)
              )
              .map((f) => f.id),
          },
          width: 550,
          actions: [
            {
              text: this.$t("general.okay"),
              validate: true,
              click: ({ fully, partially, valid }) => {
                if (!valid) {
                  return;
                }

                this.saveAor(
                  this.hubs.map((m) => ({
                    admin2PCode: this.selected.pcode,
                    hubTypeId: m.id,
                    partially: partially.includes(m.id),
                    toBeDeleted:
                      !partially.includes(m.id) && !fully.includes(m.id),
                  }))
                );
                this.closeDialog();
              },
              color: "primary",
            },
            {
              text: this.$t("general.cancel"),
              click: () => this.closeDialog(),
              color: "primary",
            },
          ],
        };
        this.showDialog(dialog);
      }
    },
  },
};
</script>
