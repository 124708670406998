<template>
  <v-container>
    <p>{{ params.body }}</p>
    <v-form ref="form" v-model="params.valid">
      <v-simple-table dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">
                {{ $t("aor.hubName") }}
              </th>
              <th class="text-left">
                {{ $t("aor.fully") }}
              </th>
              <th>
                {{ $t("aor.partially") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(hub, i) in params.hubs" :key="i">
              <td v-text="hub.name"></td>
              <td class="text-right">
                <v-switch
                  v-model="params.fully"
                  :value="hub.id"
                  dense
                  @change="updateFully(hub.id)"
                ></v-switch>
              </td>
              <td class="text-right">
                <v-switch
                  v-model="params.partially"
                  :value="hub.id"
                  dense
                  @change="updatePartially(hub.id)"
                ></v-switch>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-form>
  </v-container>
</template>

<script>
export default {
  name: "AorDialog",
  model: {
    prop: "params",
    event: "input",
  },
  props: {
    params: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      selected: [],
      value: false,
    };
  },
  methods: {
    updateFully(id) {
      if(this.params.fully.includes(id)) {
        this.params.partially = this.params.partially.filter(f => f !== id);
      }
    },
    updatePartially(id) {
      if(this.params.partially.includes(id)) {
        this.params.fully = this.params.fully.filter(f => f !== id);
      }
    },
    validate() {
      this.$refs.form.validate();
    },
  },
};
</script>
